<script setup lang = "ts">
import { ref, nextTick, computed, type Ref, onBeforeUnmount, onBeforeMount } from "vue";
import { hapticsImpact } from "./utils.ts";

import { DataObject } from "o365-dataobject";
import { $t } from "o365-utils";
import { context, Context } from "o365-modules";
import { useFieldFilterControls } from "o365-filter-components";
import Filter from "./controls.filter.Filter.js";

import MContainer from "./components.Container.vue";
import MContent from "./components.Content.vue";

// copied from o365-filterobject.FilterObject.ts
interface SavedFilter {
    PrimKey: string,
    FilterCriteria: string,
    FilterName: string
}

const props = defineProps<{
    filter: Filter;
    dataObject: DataObject;
    sharedOnly?: boolean;
    includeHidden?: boolean
}>();

const { getFiltersList } = useFieldFilterControls(null, props.dataObject.filterObject);
const dsFilters: DataObject = props.dataObject.filterObject.filtersListDO;
const vContext: Ref<Context> = ref(context);
const isNoFilterButtonSelected: Ref<boolean> = ref(false);

// get a list of user's saved filters.
const filtersList = computed(() => {
    if (!props.sharedOnly) return getFiltersList(vContext.value).filter(x => x.Mine);
    if (props.includeHidden) return getFiltersList(vContext.value).filter(x => !x.Mine);
     
    return getFiltersList(vContext.value).filter(x => !x.Mine && !x.Hidden); 
});

const selectNoFilterButton = (): void => {
    setFilterIndex(-1);
    isNoFilterButtonSelected.value = true;
}

// choose filter
const setFilterIndex = (filterIndex: number): void => {
    isNoFilterButtonSelected.value = false;
    dsFilters.setCurrentIndex(filterIndex);
}

const clearAllFitlers = (): void => {
    if (!props.filter.savedFilter) {
        return;
    }
    
    props.filter.removeSavedFilter();
    props?.dataObject?.filterObject?.clear();

    props.filter.isSavedFiltersShown= false;
    props.filter.showFieldSheet = false; 

    hapticsImpact("SelectionClick");
}

// select fitler and close bottomsheet
const applySavedFilter = async (): Promise<void> => {
    const filter: SavedFilter = {
        PrimKey: dsFilters.current?.["PrimKey"],
        FilterCriteria: dsFilters.current?.["FilterCriteria"],
        FilterName: dsFilters.current?.["FilterName"]
    }
    
    await props.dataObject.filterObject.applySavedFilter(filter);
    props.filter.selectSavedFilter(filter);

    props.filter.isSavedFiltersShown= false;
    props.filter.showFieldSheet = false; 

    hapticsImpact("SelectionClick");
};

// setting event based on filter or d
const setSavedFitlter = async (): Promise<void> => {
    if(isNoFilterButtonSelected.value) {
        return clearAllFitlers();
    }

    return await applySavedFilter();
};

// #region LifecycleHooks
nextTick((): void => {
    if (!dsFilters.state.isLoaded) {
        dsFilters.load();
    }
})

onBeforeMount((): void => {
    if(!props.dataObject.filterObject.activeFilter && dsFilters.current) {
        setFilterIndex(-1);
    }

    if (!props.filter.savedFilter?.PrimKey) {
        isNoFilterButtonSelected.value = true;
    }
});

// clear search to show unfiltered data on new open
onBeforeUnmount((): void => {
    if (!props.dataObject.filterObject.activeFilter) {
        setFilterIndex(-1);
        props.filter.isSavedFiltersShown= false;
    }
});

// #endregion

</script>

<template>
    <m-container :showSpinner="props.dataObject.filterObject.filtersListDO.state.isLoading">
        <m-content>
            <div class="mt-2 px-3 pb-3">
            <!-- hide list items when ds is loading -->
                <template v-if="filtersList.length && !props.dataObject.filterObject.filtersListDO.state.isLoading">
                    <template v-for="(filter, index) in filtersList">
                        <a class="d-flex align-items-center gap-2 list-item" 
                            :class="{ 'border-top': index > 0 }" 
                            style="color: black; font-size: 0.9em; padding: 0 1rem; text-decoration: none;" 
                            @click="setFilterIndex(filter.index)"
                        >
                            <div class="flex-shrink-0">
                                <i v-if="!dsFilters?.current?.index && filter.PrimKey === props.filter?.savedFilter?.PrimKey && !isNoFilterButtonSelected
                                || dsFilters?.current?.index === filter.index && !isNoFilterButtonSelected" 
                                    class="fas fa-dot-circle text-primary"      
                                    style="font-size: 1.5em;" 
                                />
                                <i v-else  
                                    class="far fa-circle text-muted" 
                                    style="font-size: 1.5em;" 
                                />
                            </div>
                            <div class="flex-shrink-0" style="padding: 0.75rem 0;">
                                {{ $t(filter.FilterName) }}
                            </div>
                        </a>

                    </template>
                    <a class="d-flex align-items-center gap-2 list-item border-top"  
                        style="color: black; font-size: 0.9em; padding: 0 1rem; text-decoration: none;" 
                        @click="selectNoFilterButton"
                    >
                        <div class="flex-shrink-0">
                            <i v-if="isNoFilterButtonSelected && dsFilters?.current?.PrimKey !== props.filter.savedFilter" 
                                class="fas fa-dot-circle text-primary"      
                                style="font-size: 1.5em;" 
                            />
                            <i v-else  
                                class="far fa-circle text-muted" 
                                style="font-size: 1.5em;" 
                            />
                        </div>
                        <div class="flex-shrink-0" style="padding: 0.75rem 0;">
                            {{ $t("No filter selected")}}
                        </div>
                    </a>
                </template>
                <template v-else>
                    <template v-if="!props.dataObject.filterObject.filtersListDO.state.isLoading">
                        <div class="p-3 d-flex justify-content-center text-muted">
                            {{ $t("No results found") }}
                        </div>
                    </template>
                </template>
            </div>
        </m-content>
        <transition name="raise">
            <template v-if="filtersList.length">
                <template v-if="dsFilters?.current || isNoFilterButtonSelected">
                    <div class="p-3 d-flex">
                        <button class="flex-grow-1 btn btn-primary" @click="setSavedFitlter">
                            {{ $t("Apply") }}
                        </button>
                    </div>
                </template>
            </template>
        </transition>
    </m-container>
</template>
